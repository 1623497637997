export const environment = {
	production: true,
	firebaseConfig: {
		apiKey: 'AIzaSyCXCeMqoHgnWMr7d8qEZRyeBs4bB-o3v0M',
		authDomain: 'website-v2-330a2.firebaseapp.com',
		projectId: 'website-v2-330a2',
		storageBucket: 'website-v2-330a2.appspot.com',
		messagingSenderId: '336876485758',
		appId: '1:336876485758:web:3ab79871b9e8ecd444ec2f',
		measurementId: 'G-PTB012LSHD',
	},
};
