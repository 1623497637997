import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../data/general.data';
import * as fromPrivate from 'src/app/store/private';
import { map } from 'rxjs/operators';
import { IEntryHashMap } from '../store/private/private.model';

@Injectable()
export class PrivatePagesGuardService {
	constructor(private readonly router: Router, private readonly store: Store<IAppState>) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const entryHashes: Observable<any> = this.store.select(fromPrivate.selectEntryHashesMap);
		const enteredKey = localStorage.getItem('enteredKey');

		if (!enteredKey) {
			this.router.navigate(['']);
			return of(false);
		}

		return entryHashes.pipe(
			map((entryHashMap: IEntryHashMap) => {
				const hashedKeyExists = enteredKey in entryHashMap;

				if (hashedKeyExists && entryHashMap[enteredKey].redirect === 'master') {
					return true;
				} else if (hashedKeyExists && entryHashMap[enteredKey].redirect === 'writings') {
					const requestedPath = route.url[0].path;
					const entry = Object.values(entryHashMap).filter((entry) => entry.redirect === requestedPath)[0];
					return entry?.public || requestedPath === 'writings';
				} else if (hashedKeyExists) {
					const requestedPath = route.url[0].path;

					if (requestedPath !== entryHashMap[enteredKey].redirect) {
						this.router.navigate(['']);
						return false;
					}

					return true;
				}
				return false;
			})
		);
	}
}
