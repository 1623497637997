import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	declarations: [SpinnerComponent, SpinnerOverlayComponent],
	exports: [SpinnerComponent],
	imports: [CommonModule, OverlayModule, BrowserAnimationsModule],
})
export class SpinnerModule {}
