import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UiModule } from '@ui/ui.module';
import { BtnModule } from '@btn/btn.module';

@Component({
	selector: 'app-not-found-page',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
	imports: [CommonModule, UiModule, BtnModule],
	standalone: true,
})
export class NotFoundComponent implements OnInit {
	constructor(private readonly router: Router) {}

	ngOnInit(): void {}

	onNavigateHome(): void {
		this.router.navigate(['/']);
	}
}
