import { Injectable } from '@angular/core';
import { collection, Firestore, orderBy, query, getDocs, Query } from '@angular/fire/firestore/lite';
import { from, Observable } from 'rxjs';
import { IEntryHash } from './private.model';
import { CollectionReference, DocumentData, QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore/lite';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PrivateService {
	entryHashesCollection: CollectionReference<DocumentData>;
	HASHES_COLLECTION = 'entryHashes';

	constructor(private readonly firestore: Firestore) {
		this.entryHashesCollection = collection(firestore, this.HASHES_COLLECTION);
	}

	getEntryHashes(): Observable<IEntryHash[]> {
		const queryData = query(this.entryHashesCollection, orderBy('createdDate', 'desc'));
		return this.fetchDocs(queryData);
	}

	fetchDocs = (queryData: Query) => from(getDocs(queryData)).pipe(map(this.transformSnapshotToHash));

	transformSnapshotToHash = (snapshot: QuerySnapshot<DocumentData>) => snapshot.docs.map(this.createEntryHash);

	createEntryHash = (doc: QueryDocumentSnapshot<DocumentData>) => ({ ...doc.data() } as IEntryHash);
}
