import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Action, StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore/lite';
import { entryHashesReducer } from './store/private/private.reducers';
import { PrivateEffects } from './store/private/private.effects';
import { SpinnerModule } from './modules/spinner/spinner.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivatePagesGuardService } from './service/private-pages-guard.service';
import { IEntryHashState } from './store/private/private.model';
import { provideCharts } from 'ng2-charts';
import { BarController, BarElement, CategoryScale, Colors, Legend, LinearScale } from 'chart.js';
import { CommonModule } from '@angular/common';

export const reducers: { entryHash: (state: IEntryHashState | undefined, action: Action) => IEntryHashState } = {
	entryHash: entryHashesReducer,
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		EffectsModule.forRoot([PrivateEffects]),
		StoreModule.forRoot(reducers),
		SpinnerModule,
		BrowserAnimationsModule,
	],
	providers: [
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideFirestore(() => getFirestore()),
		PrivatePagesGuardService,
		provideCharts({ registerables: [BarController, Legend, Colors, LinearScale, CategoryScale, BarElement] }),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
