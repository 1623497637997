import { IEntryHashState } from './private.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromPrivate from '../private';

export const initialState: IEntryHashState = {
	isLoading: false,
	isLoaded: false,
	entryHashes: [],
};

const entryHashesReducerHandler = createReducer(
	initialState,
	on(fromPrivate.loadHashes, (state) => ({
		...state,
		isLoading: true,
		isLoaded: false,
	})),
	on(fromPrivate.loadHashesSuccess, (state, { entryHashes }) => ({
		...state,
		entryHashes,
		isLoading: false,
		isLoaded: true,
	}))
);
export function entryHashesReducer(state: IEntryHashState | undefined, action: Action): IEntryHashState {
	return entryHashesReducerHandler(state, action);
}
